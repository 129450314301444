import React from 'react'
import {Link} from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import { Theme } from "@material-ui/core"
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'



const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            paddingBottom: 10,
            width: '25%',
            marginRight: 10,
            marginLeft: 10,
            marginBottom: 20,
            '&:hover':{
                boxShadow: '0px 25px 35px rgba(28, 28, 61, 0.18)'
            }
        },
        media: {
            height: 450,
            position: 'relative',
        },
        boxTitle: { 
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '70px',
            alignItems: 'center',
            opacity: 0.7,
            color: '#000',                      
        },
        boxTitleSpan:{
            fontSize: '32px',
            fontWeight: 'bold'
        },
        '@media (max-width: 800px)':{
            root: {
                width: '46%',
            },
            media: {
                height: 250,
            },
        },
        '@media (max-width: 420px)':{
            root: {
                width: '100%',
                marginRight: 0,
                marginLeft: 0,
                marginBottom: 20,
                paddingBottom: 10,
                boxShadow: '0px 25px 35px rgba(28, 28, 61, 0.18)'
            },
            media: {
                height: 250,
                position: 'relative',
            },
        }
    }
})

interface IPropsMenuProduct{
    product: any
}

const MenuProduct: React.FC<IPropsMenuProduct> = (props) => {

    const classes = useStyles()
    const {product} = props
    
    return (
        <Card className={classes.root}>
            <Link to={"/product/" + product.slug} style={{textDecoration: 'none', cursor: 'pointer'}} >
                <CardMedia className={classes.media} image={`/images/${product.image}`} title={product.title}>
                   
                </CardMedia>
                <div className={classes.boxTitle}>
                        <span className={classes.boxTitleSpan}> {product.title}</span>
                </div>
            </Link> 
        </Card>
    )
}

export default MenuProduct
