import * as React from "react"
import { makeStyles } from '@material-ui/styles'
import { Theme} from '@material-ui/core'
import HeaderEdit from '../components/header-edit'
import SEO from "../components/seo"
import MenuProductList from '../components/menu-product-list'

const useStyles = makeStyles((theme: Theme) => {
  return {
      
      backgroundImage:{
          width:'100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left:'0',
          zIndex: -1,
          display: 'none'
      },
      divTopTitle:{
          marginTop:70,
          marginRight: 'auto',
          marginLeft: 'auto',
      },
      homeTopTitleText:{
          position: 'relative',
          marginTop: 0,            
          fontSize: '4.4rem',
          lineHeight: '6.9rem',
          fontWeight: 600,
          color: '#2F519C',
          marginBottom: '0.5rem',
          zIndex: 10,
          textAlign: 'center',            
      },
      homeTopTitleTextSub:{
          marginTop: 0,            
          fontSize: '1.5rem',
          lineHeight: '1rem',
          fontWeight: 600,
          color: '#000',
          marginBottom: '1.6rem',
          textAlign: 'center',            
      },
      homeBtnSelectRecipe:{
          backgroundColor: '#2F519C',
          color: '#fff',
          width: '220px',
          height: '60px',
          border: 'solid 1px #2F519C',
          borderRadius: '25px',
          fontSize: '18pt',
          fontWeight: 600,
          marginTop: 50,
          padding: '12px 50px',       
          '&:hover': {
              backgroundColor: '#3b65c3',
              textDecoration: 'none',
          }
      },
      homeSectionHowItWorks: {
          backgroundColor: '#ECF0F9',
          paddingBottom: '6rem',
          paddingTop: '6rem',
          marginTop: '302px',
      },
      btnSelectRecipeContainer:{
          marginTop: 50,
      },
      divFooter:{
          display: 'block',
      },
      divFooterMobile: {
          display: 'none',
      },
      divMainContainer:{
          marginTop: 100
      },
      '@media (max-width: 1350px)':{
          container:{
              width: 'unset',
          },
          homeTopTitleText:{
              fontSize: '2.4rem',
              lineHeight: '3rem',
              textAlign: 'unset',
              marginRight: 'unset',
          },
          homeTopTitleTextSub:{                 
              textAlign: 'unset',
              marginRight: 'unset',               
          },             
      },
      '@media (max-width: 420px)':{            
          homeTopTitleTextSub:{
              display: 'none',                
          },           
          divFooter:{
              display: 'none',
          },
          divFooterMobile: {
              display: 'block',
          },
          divMainContainer:{
              overflowX: 'hidden',
              marginTop: 55
          },     
      }
  }
})

const Products: React.FC = () => {
  const classes = useStyles()
  return(
      <div>
          <HeaderEdit/>
          <SEO title="Products" />
          <div  className={classes.divMainContainer}>
            <div style={{}}>
                <MenuProductList/>
            </div>
            <div className={classes.divFooter}>
                {/* <Footer /> */}
            </div>
            <div className={classes.divFooterMobile}>
                {/* <FooterMobile /> */}
            </div> 
         </div>
      </div>
  )
}

export default Products

