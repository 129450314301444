import React from 'react'
import MenuProduct from './menu-product'
import MenuProductData from '../content/menu-product-data.json'
import { makeStyles } from '@material-ui/styles'
import { Theme, Button } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
    return {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            margin: 30,
        }
    }
})

const MenuProductList: React.FC = () => {

    const classes = useStyles()
    const {menuProducts} = MenuProductData
    
    return (
        <div className={classes.root}>
            {menuProducts.map((item)=>{
                return <MenuProduct key={item.slug} product={item}/>
            })}
        </div>
    )
}

export default MenuProductList
